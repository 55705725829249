import React, { useState } from "react";
import { PATIENT_WEBSITE } from "../constants";

export default function ChronicCare(props) {
  const [index, setIndex] = useState(0);

  const inactive =
    "w-1/4 py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300";
  const active =
    "w-1/4 py-4 px-1 text-center border-b-2 border-indigo-500 font-medium text-sm leading-5 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700";
  return (
    <div className="overflow-hidden bg-white rounded-lg shadow">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="px-4 py-5 bg-white border-b border-gray-200 sm:px-6">
          <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-no-wrap">
            <div className="mt-2 ml-4">
              <h3 className="text-2xl font-medium leading-6 text-gray-900">
                {props.title}
              </h3>
            </div>
            <div className="flex-shrink-0 mt-2 ml-4">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    window.location.href = PATIENT_WEBSITE;
                  }}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                  // onClick={}
                >
                  {props.buttonTitle}
                </button>
              </span>
            </div>
          </div>
        </div>

        {/* <!-- We use less vertical padding on card headers on d
      
         
         esktop than on body sections --> */}
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div>
          <div className="sm:hidden">
            <select
              // onClick={e => {
              //   // console.log("index is:", e.target.value);
              //   setIndex(Number(e.target.value));
              // }}
              onChange={e => {
                setIndex(Number(e.target.value));
              }}
              value={index}
              className="block w-full form-select"
            >
              <option value={0}>Asthma</option>
              <option value={1}>High Cholesterol</option>
              <option value={2}>High Blood Pressure</option>
              <option value={3}>Weight Management</option>
              <option value={4}>Diabetes</option>
              <option value={5}>Thyroid issues</option>
            </select>
          </div>
          <div className=" sm:block">
            <div className="border-b border-gray-200">
              <p className="pt-10 text-sm text-gray-600 sm:px-5 sm:text-lg md:text-xl sm:font-light sm:font-medium">
                {" "}
                When you’re sick and need to see a doctor, our team is standing
                by around the clock. Available 24/7, our providers can help get
                you on track as well as order prescriptions, if needed. We’ll
                take the hassle and guesswork out of feeling better. For select
                health plans and employers, we can offer treatment programs and
                plans to help manage specific issues and conditions.
              </p>

              <nav className="hidden -mb-px cursor-pointer sm:flex">
                <a
                  //   href="#"
                  onClick={() => {
                    setIndex(0);
                  }}
                  className={index === 0 ? active : inactive}
                >
                  Asthma
                </a>
                <a
                  onClick={() => {
                    setIndex(1);
                  }}
                  className={index === 1 ? active : inactive}
                >
                  High Cholesterol
                </a>
                <a
                  onClick={() => {
                    setIndex(2);
                  }}
                  className={index === 2 ? active : inactive}
                >
                  High Blood Pressure
                </a>
                <a
                  onClick={() => {
                    setIndex(3);
                  }}
                  className={index === 3 ? active : inactive}
                >
                  Weight Management
                </a>
                <a
                  onClick={() => {
                    setIndex(4);
                  }}
                  className={index === 4 ? active : inactive}
                >
                  Diabetes
                </a>
                <a
                  onClick={() => {
                    setIndex(5);
                  }}
                  className={index === 5 ? active : inactive}
                >
                  Thyroid Issues
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div className="py-9">
          {index === 0 && (
            <div className="flex flex-row justify-around px-5 pt-10 text-lg font-medium text-gray-600">
              <ul className="flex flex-col text-indigo-500">
                <p>Allergic Asthma</p>
                <p>Exercise Tolerance</p>
                <p>And more</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Allergy Testing</p>
                <p>Inflammation</p>
              </ul>
            </div>
          )}
          {index === 1 && (
            <div className="flex flex-row justify-around pt-10 text-lg font-medium text-gray-600 sm:px-5">
              <ul className="flex flex-col text-indigo-500">
                <p>Lab Screening</p>
                <p>Obesity</p>
                <p>Diabetes</p>
                <p>And more</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Exercise Counseling</p>
                <p>Diet and nutrition</p>
                <p>And more</p>
              </ul>
            </div>
          )}
          {index === 2 && (
            <div className="flex flex-row justify-around px-5 pt-10 text-lg font-medium text-gray-600">
              <ul className="flex flex-col text-indigo-500">
                <p>Obesity</p>
                <p>Diet and Nutrition</p>
                <p>Metabolic Syndrome</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Stress</p>
                <p>Medication Management</p>
                <p>Medication Questions/Dosing</p>
                <p>And more</p>
              </ul>
            </div>
          )}
          {index === 3 && (
            <div className="flex flex-row justify-around px-5 pt-10 text-lg font-medium text-gray-600">
              <ul className="flex flex-col text-indigo-500">
                <p>Exercise counseling</p>
                <p>Diet and nutrition</p>
                <p>And more</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Weight loss</p>

                <p>Stress</p>
              </ul>
            </div>
          )}
          {index === 4 && (
            <div className="flex flex-row justify-around px-5 pt-10 text-lg font-medium text-gray-600">
              <ul className="flex flex-col text-indigo-500">
                <p>Heart health</p>
                <p>Supplies & refills</p>
                <p>And more</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Kidney health</p>
                <p>Diet and nutrition</p>
                <p>Insulin adjustment</p>
              </ul>
            </div>
          )}
          {index === 5 && (
            <div className="flex flex-row justify-around px-5 pt-10 text-lg font-medium text-gray-600">
              <ul className="flex flex-col text-indigo-500">
                <p>Weight loss</p>
                <p>Anxiety</p>
                <p>Thyroid function screening</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Weight gain</p>
                <p>Heart palpitations</p>
                <p>Fatigue and more</p>
              </ul>
            </div>
          )}
        </div>
        {/* <!-- Content goes here --> */}
      </div>
    </div>
  );
}
