import React, { useState } from "react";
import { Link } from "gatsby";
import { PATIENT_WEBSITE } from "../constants";

export default function CardWithAction(props) {
  const [index, setIndex] = useState(0);

  const inactive =
    "w-1/4 py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300";
  const active =
    "w-1/4 py-4 px-1 text-center border-b-2 border-indigo-500 font-medium text-sm leading-5 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700";
  return (
    <div className="overflow-hidden bg-white rounded-lg shadow">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="px-4 py-5 bg-white border-b border-gray-200 sm:px-6">
          <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-no-wrap">
            <div className="mt-2 ml-4">
              <h3 className="text-2xl font-medium leading-6 text-gray-900">
                {props.title}
              </h3>
            </div>
            <div className="flex-shrink-0 mt-2 ml-4">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    window.location.href = PATIENT_WEBSITE;
                  }}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                >
                  {props.buttonTitle}
                </button>
              </span>
            </div>
          </div>
        </div>

        {/* <!-- We use less vertical padding on card headers on desktop than on body sections --> */}
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div>
          <div className="sm:hidden">
            <select
              // onClick={e => {
              //   // console.log("index is:", e.target.value);
              //   setIndex(Number(e.target.value));
              // }}
              onChange={e => {
                setIndex(Number(e.target.value));
              }}
              value={index}
              className="block w-full form-select"
            >
              <option value={0}>Cold & Flu</option>
              <option value={1}>Skin Conditions</option>
              <option value={2}>Women's Health</option>
              <option value={3}>Men's Health</option>
              <option value={4}>Allergies</option>
              <option value={5}>Headaches</option>
            </select>
          </div>
          <div className=" sm:block">
            <div className="border-b border-gray-200">
              <p className="pt-10 text-sm text-gray-600 sm:px-5 sm:text-lg md:text-xl sm:font-light sm:font-medium">
                When you’re sick and need to see a doctor, our team is standing
                by around the clock. Available 24/7, our providers can help get
                you on track as well as order prescriptions, if needed. We’ll
                take the hassle and guesswork out of feeling better. For select
                health plans and employers, we can offer treatment programs and
                plans to help manage specific issues and conditions.
              </p>

              <nav className="hidden -mb-px cursor-pointer sm:flex">
                <a
                  //   href="#"
                  onClick={() => {
                    setIndex(0);
                  }}
                  className={index === 0 ? active : inactive}
                >
                  Cold & Flu
                </a>
                <a
                  onClick={() => {
                    setIndex(1);
                  }}
                  className={index === 1 ? active : inactive}
                >
                  Skin Conditions
                </a>
                <a
                  onClick={() => {
                    setIndex(2);
                  }}
                  className={index === 2 ? active : inactive}
                >
                  Women's Health
                </a>
                <a
                  onClick={() => {
                    setIndex(3);
                  }}
                  className={index === 3 ? active : inactive}
                >
                  Men's Health
                </a>
                <a
                  onClick={() => {
                    setIndex(4);
                  }}
                  className={index === 4 ? active : inactive}
                >
                  Allergies
                </a>
                <a
                  onClick={() => {
                    setIndex(5);
                  }}
                  className={index === 5 ? active : inactive}
                >
                  Headaches
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div className="py-9">
          {index === 0 && (
            <div className="flex flex-row justify-around px-5 pt-10 text-lg font-medium text-gray-600">
              <ul className="flex flex-col text-indigo-500">
                <p>Cold & Flu</p>
                <p>Fever</p>
                <p>Cough</p>
                <p>Sinus infections</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Headaches</p>
                <p>Asthma</p>
                <p>And more</p>
              </ul>
            </div>
          )}
          {index === 1 && (
            <div className="flex flex-row justify-around px-5 pt-10 text-lg font-medium text-gray-600">
              <ul className="flex flex-col text-indigo-500">
                <p>Acne</p>
                <p>Cellulitis</p>
                <p>Hives</p>
                <p>And more</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Rashes</p>
                <p>Allergies</p>
                <p>Sunburn</p>
              </ul>
            </div>
          )}
          {index === 2 && (
            <div className="flex flex-row justify-around px-5 pt-10 text-lg font-medium text-gray-600">
              <ul className="flex flex-col text-indigo-500">
                <p>UTis</p>
                <p>Fatigue</p>
                <p>Migraines</p>
                <p>And more</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Yeast infections</p>
                <p>Metabolic syndrome</p>
                <p>Birth Control</p>
                <p>Hypertension</p>
              </ul>
            </div>
          )}
          {index === 3 && (
            <div className="flex flex-row justify-around px-5 pt-10 text-lg font-medium text-gray-600">
              <ul className="flex flex-col text-indigo-500">
                <p>Joint pain</p>
                <p>STIs</p>
                <p>Erectile Dysfunction</p>
                <p>And more</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Stress management</p>
                <p>IBS</p>
                <p>Prostatitis</p>
                <p>Hypertension</p>
              </ul>
            </div>
          )}
          {index === 4 && (
            <div className="flex flex-row justify-around px-5 pt-10 text-lg font-medium text-gray-600">
              <ul className="flex flex-col text-indigo-500">
                <p>Common Allergies</p>
                <p>Skin rashes</p>
                <p>Epipen refills</p>
                <p>And more</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Seasonal allergies</p>
                <p>Allergic sinusitis</p>
                <p>Hives</p>
              </ul>
            </div>
          )}
          {index === 5 && (
            <div className="flex flex-row justify-around px-5 pt-10 text-lg font-medium text-gray-600">
              <ul className="flex flex-col text-indigo-500">
                <p>Tension headaches</p>
                <p>Nausea</p>
                <p>Migraines</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Sinus headaches</p>
                <p>Stress and anxiety</p>
                <p>And more</p>
              </ul>
            </div>
          )}
        </div>
        {/* <!-- Content goes here --> */}
      </div>
    </div>
  );
}
