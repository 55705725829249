import React from "react";
import { PATIENT_WEBSITE } from "../constants";
import { Link } from "gatsby";

export default function CTASimpleJustified() {
  const handleClick = trackingTitleString => {
    if (window.analytics) {
      window.analytics.track(trackingTitleString);
    }
  };
  return (
    <div className=" bg-gray-50">
      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Patients love using SwyftDoc.
          <br />
          <span className="text-indigo-600">Get started today.</span>
        </h2>
        <div className="flex mt-8 lg:flex-shrink-0 lg:mt-0">
          <div className="inline-flex rounded-md shadow cursor-pointer">
            <a
              href={PATIENT_WEBSITE}
              target="_blank"
              onClick={() => {
                handleClick("Hero->PatientWebsite");
              }}
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:shadow-outline"
            >
              Get started
            </a>
          </div>
          {/* <div className="inline-flex ml-3 rounded-md shadow">
            <a
              href="#"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-indigo-600 transition duration-150 ease-in-out bg-white border border-transparent rounded-md hover:text-indigo-500 focus:outline-none focus:shadow-outline"
            >
              Learn more
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
