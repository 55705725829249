import React, { useState } from "react";
import { PATIENT_WEBSITE } from "../constants";

export default function PreventativeHealth(props) {
  const [index, setIndex] = useState(0);

  const inactive =
    "w-1/4 py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300";
  const active =
    "w-1/4 py-4 px-1 text-center border-b-2 border-indigo-500 font-medium text-sm leading-5 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700";
  return (
    <div className="overflow-hidden bg-white rounded-lg shadow">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="px-4 py-5 bg-white border-b border-gray-200 sm:px-6">
          <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-no-wrap">
            <div className="mt-2 ml-4">
              <h3 className="text-2xl font-medium leading-6 text-gray-900">
                {props.title}
              </h3>
            </div>
            <div className="flex-shrink-0 mt-2 ml-4">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    window.analytics.track(
                      "PreventativeHealth->PatientWebsite"
                    );
                    window.location.href = PATIENT_WEBSITE;
                  }}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                >
                  {props.buttonTitle}
                </button>
              </span>
            </div>
          </div>
        </div>

        {/* <!-- We use less vertical padding on card headers on desktop than on body sections --> */}
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div>
          <div className="sm:hidden">
            <select
              // onClick={e => {
              //   // console.log("index is:", e.target.value);
              //   setIndex(Number(e.target.value));
              // }}
              onChange={e => {
                setIndex(Number(e.target.value));
              }}
              value={index}
              className="block w-full form-select"
            >
              <option value={0}>Wellness Visits</option>
              <option value={1}>Family Medicine</option>
              <option value={2}>Women's Wellness</option>
              <option value={3}>Men's Wellness</option>
              <option value={4}>Diet & Nutrition</option>
              <option value={5}>Medications</option>
            </select>
          </div>
          <div className=" sm:block">
            <div className="border-b border-gray-200">
              <p className="pt-10 text-sm text-gray-600 sm:px-5 sm:text-lg md:text-xl sm:font-light sm:font-medium">
                Our attentive care team partners with you to support your
                day-to-day health and self-care routines. From healthy eating to
                preventive lab screenings, we bring together trusted providers
                with solutions that actually work in the real world.
              </p>

              <nav className="hidden -mb-px cursor-pointer sm:flex">
                <a
                  //   href="#"
                  onClick={() => {
                    setIndex(0);
                  }}
                  className={index === 0 ? active : inactive}
                >
                  Wellness Visits
                </a>
                <a
                  onClick={() => {
                    setIndex(1);
                  }}
                  className={index === 1 ? active : inactive}
                >
                  Family Medicine
                </a>
                <a
                  onClick={() => {
                    setIndex(2);
                  }}
                  className={index === 2 ? active : inactive}
                >
                  Women's Wellness
                </a>
                <a
                  onClick={() => {
                    setIndex(3);
                  }}
                  className={index === 3 ? active : inactive}
                >
                  Men's Wellness
                </a>
                <a
                  onClick={() => {
                    setIndex(4);
                  }}
                  className={index === 4 ? active : inactive}
                >
                  Diet & Nutrition
                </a>
                <a
                  onClick={() => {
                    setIndex(5);
                  }}
                  className={index === 5 ? active : inactive}
                >
                  Medications
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div className="sm:py-9">
          {index === 0 && (
            <div className="flex flex-row justify-around pt-10 text-lg font-medium text-gray-600 sm:px-5">
              <ul className="flex flex-col text-indigo-500">
                <p>Lifestyle counseling</p>
                <p>Labs & screenings</p>
                <p>Screening recommendations based on age</p>
                <p>Emotional Health</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Smoking cessation</p>
                <p>And more</p>
              </ul>
            </div>
          )}
          {index === 1 && (
            <div className="flex flex-row justify-around pt-10 text-lg font-medium text-gray-600 sm:px-5">
              <ul className="flex flex-col text-indigo-500">
                <p>Vitamin Deficiency</p>
                {/* <p>Pediatric issues: rashes, colds, fevers</p> */}
                <p>Immunization plans</p>
                <p>Growth</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Childhood development counseling</p>
                <p>And more</p>
              </ul>
            </div>
          )}
          {index === 2 && (
            <div className="flex flex-row justify-around pt-10 text-lg font-medium text-gray-600 sm:px-5">
              <ul className="flex flex-col text-indigo-500">
                <p>Heart Health</p>
                <p>Weight Management</p>
                <p>Hormone replacement counseling</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Labs and screenings</p>
                <p>Skin conditions</p>
                <p>And more</p>
              </ul>
            </div>
          )}
          {index === 3 && (
            <div className="flex flex-row justify-around pt-10 text-lg font-medium text-gray-600 sm:px-5">
              <ul className="flex flex-col text-indigo-500">
                <p>Heart health</p>
                <p>Behavioral Health screenings</p>
                {/* <p>Prostate cancer risk counseling</p> */}
                <p>Metabolic syndrome</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Pre-diabetes screening</p>
                <p>And more</p>
              </ul>
            </div>
          )}
          {index === 4 && (
            <div className="flex flex-row justify-around pt-10 text-lg font-medium text-gray-600 sm:px-5">
              <ul className="flex flex-col text-indigo-500">
                <p>Weight management</p>
                <p>Vitamin deficiencies</p>
                <p>Obesity</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Metabolic Syndrome</p>
                <p>And more</p>
              </ul>
            </div>
          )}
          {index === 5 && (
            <div className="flex flex-row justify-around pt-10 text-lg font-medium text-gray-600 sm:px-5">
              <ul className="flex flex-col text-indigo-500">
                <p>Dosage changes</p>
                <p>Medication list reviews and refills</p>
                <p>Medication interactions</p>
              </ul>
              <ul className="flex flex-col text-indigo-500">
                <p>Safe use of generic prescriptions</p>
                <p>General medication questions</p>
                <p>And more</p>
              </ul>
            </div>
          )}
        </div>
        {/* <!-- Content goes here --> */}
      </div>
    </div>
  );
}
