import React from "react";

export default function WhatWeDontTreat() {
  return (
    <div className="overflow-hidden bg-white border-2 border-yellow-300 rounded-lg shadow" >
      <div className="px-4 py-5 text-lg font-medium font-bold text-gray-900 border-b border-gray-200 sm:px-6">
        {/* <!-- Content goes here --> */}
        {/* <p className="text-lg font-medium text-gray-900 "> */}
        What we don't treat
        {/* </p> */}
        {/* <!-- We use less vertical padding on card headers on desktop than on body sections --> */}
      </div>
      <div className="px-4 py-5 sm:p-6">
        <p>
          While our doctors are able to treat the top issues you would go see a
          doctor about, there are conditions that we don’t treat. Please see an
          in-person doctor or hospital if you experience any of the following:
        </p>
        <div className="flex flex-row justify-center">
          <ul>
            <p>- Traumatic brain or spinal cord injury</p>
            <p>- Vomiting or coughing blood</p>
            <p>- Chest pain and/or numbness</p>
            <p>- Lacerations</p>
          </ul>
          <ul>
            <p>- Loss of consciousness</p>
            <p>- Broken bones</p>
            <p>- Severe burns</p>
            <p>- Pediatric ear infections</p>
          </ul>
        </div>

        <b>
          Our doctors are unable to write prescriptions for controlled
          substances such as codeine or oxycodone. Please see a doctor in person
          if you require medication classified as a controlled substance.
        </b>
      </div>
    </div>
  );
}
